export default {
    "app": {
        "title": "Among Us Tracker",
        "sourceCode": "Source Code",
        "mobileInformation": "Add new players below to start tracking. Move players between sections using the arrows.",
        "information": "Add new players below to start tracking. Move players between sections using drag and drop or the arrows."
    },
    "footer": {
        "createdBy": "Created by Kallum Parr | MIT Licence"
    },
    "dead": {
        "title": "Dead"
    },
    "impostor": {
        "title": "Impostor"
    },
    "innocent": {
        "title": "Innocent"
    },
    "players": {
        "title": "Players"
    },
    "suspicious": {
        "title": "Suspicious"
    },
    'player': {
        'up': 'Up Arrow',
        'down': 'Down Arrow'
    },
    'managePlayers': {
        'title': 'Manage Players',
        'selectPlaceholder': 'None',
        'removePlayer': 'Remove',
        'resetAllPlayers': 'Reset All'
    },
    'savePlayers': {
        'title': 'Save Players',
        'save': 'Save',
        'generalError': 'Oops, that didn\'t work. Please try again',
        'alert': 'Your session has been saved. Please bookmark or copy the URL for this page.',
    },
    'addNewPlayer': {
        'title': 'Add New Player',
        'colourAlreadyExists': 'Colour already exists',
        'nameLength': 'Max name length is 10',
        'add': 'Add',
        'addAll': 'Add All Colours',
        'playerNameField': {
            'placeholder': 'Player Name'
        },
    },
    "playerColour": {
        'unknown': 'Unknown',
        'black': 'Black',
        'blue': 'Blue',
        'brown': 'Brown',
        'cyan': 'Cyan',
        'green': 'Green',
        'lime': 'Lime',
        'orange': 'Orange',
        'pink': 'Pink',
        'purple': 'Purple',
        'red': 'Red',
        'white': 'White',
        'yellow': 'Yellow',
        'banana': 'Banana',
        'coral': 'Coral',
        'grey': 'Grey',
        'maroon': 'Maroon',
        'rose': 'Rose',
        'tan': 'Tan'
    }
}